/* Utils scss */

/* Colors */
$color-main: #000;
$color-accent: #DED4CB;
$color-gray: #777777;
$color-brown: #BEAEA8;

/* Boilerplate */
body {
    font-weight: 400;
    font-size: 100%;
    background-color: $color-main;
    color: $color-accent;
    margin: 0;
    padding: 0;
}

html {
    font-size: 100%; 
    background-color: $color-main;
}

/* Fonts and Sizes */
$huge: 4rem;
$large: 3rem;
$big: 2rem;
$extra: 1.5rem;
$medium-plus: 1.25rem;
$medium: 1rem;
$small: 0.75rem;
$tiny: 0.5rem;

// Standard Tags
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 0;
    font-family: "Petrona", serif;
}

h1 {
    font-size: $huge;
}

h2 {
    font-size: $large;
}

h3 {
    font-size: $big;
}

h4 {
    font-size: $medium-plus;
}

p {
    font-size: $medium;
    font-family: "Anaheim", sans-serif;
}

a {
    font-family: "Anaheim", sans-serif;
}

/* Paths */
$images-path: "../../static/images/";

/* BG styles */
.bg-img-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-img-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/* Project elements */




/* Flex classes */
.flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-between-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-space-around-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-space-evenly-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-end-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-start-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex-center-end-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.flex-start-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-start-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-start-end {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-start-end-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-end-start {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.flex-end-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}



/* Grid classes */
.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
    grid-template-columns: repeat(5, 1fr);
}

.grid-gap-tny {
    gap: 0.5rem;
}

.grid-gap-sm {
    gap: 0.75rem;
}

.grid-gap-md {
    gap: 1rem;
}

.grid-gap-bg {
    gap: 1.5rem;
}

.grid-gap-lrg {
    gap: 2rem;
}
