@use "utils.scss";


/* Index -Desktop */

/* Index Navbar */
#index-navbar,
#navbar {
    width: 100%;
    min-height: 10vh;
}

#navbar {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4rem;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(7.5px);
    z-index: 1000;
}

.navbar-left {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    a > img {
        width: 75%;
        height: auto;
        
        cursor: pointer;       
    }
}

.navbar-right {
    width: 25%;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4rem;
    padding-right: 2rem;
}

.index-navbar-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    h2 {
        font-family: 'Petrona', serif;
        width: 33%;
        margin-top: 2rem;
        margin-left: 2rem;
        font-size: utils.$medium-plus;
        color: utils.$color-accent;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}

.index-navbar-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4rem;
    padding-right: 2rem;
}

.menu-button {
    color: utils.$color-accent;
    text-align: center;
    font-family: 'Petrona', serif;
    font-size: utils.$medium-plus;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {

        opacity: 0.5;
    }
}

.language-switcher {
    width: auto;
}

.navbar-languages {
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: nowrap;

    button {
        color: utils.$color-gray;
        font-family: 'Petrona', serif;
        text-transform: uppercase;
        font-size: utils.$medium-plus;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
        
}

/* The side navigation menu */
.sidenav {
    height: 100vh; /* Ensures it takes full screen height */
    width: 0; /* Initially hidden */
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: utils.$color-accent; 
    overflow-x: hidden;
    padding-top: 2rem; 
    transition: 0.5s; /* Smooth transition for width */
    color: utils.$color-main;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sidenav-wrapper {
    visibility: hidden; /* Hidden initially */
    opacity: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    transition: opacity 0.5s;
}

.navbar-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar-controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10vh;

    a {
        color: utils.$color-main;
        text-decoration: none;
        text-align: center;
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            color: utils.$color-brown;
        }
    }
}

#navbar-links {
    width: 100%;

}

.navbar-links-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;

    a {
        text-decoration: none;
    }
}

.navbar-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    
    h3, p {
        color: utils.$color-main;
        text-decoration: none;
        text-align: center;
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    p {
        font-size: utils.$tiny;
        margin: 0;
    }

    &:hover {

        h3,
        p {
            color: utils.$color-brown;
        }
    }
}

/* When sidenav is active */
.sidenav-active {
    width: 25vw; /* or adjust as per your requirement, like 25vw */
    height: 100vh; /* Full screen height */
}

.sidenav-active #sidenav-wrapper {
    visibility: visible; /* Make visible when active */
    opacity: 1; /* Fade in */
    display: flex;
}

#navbar-bottom-text {
    width: 100%;

    p {
        width: 80%;
        text-align: center;
        font-size: utils.$medium;
    }
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* General Styles */
.section-block {
    width: 100%;
    min-height: 100vh;
    padding-top: 10vh;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Index desktop */


#index {
    width: 100%;
    height: 90vh;
    padding-top: 0vh;
    background-color: #000;
}
.index-video-wrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#index-video {
    width: 100%;
    height: 100%;
    margin-bottom: 5vh;
    position: relative;

    video {
        object-fit: cover;
    }
}

.index-roca-logo {
    position: absolute;
    img {
    object-fit: contain;
    }
}

#index-footer {
    width: 90%;
    height: 5vh;
    gap: 2rem;
    z-index: 5;

    .index-footer-bases {

        a {
            font-size: utils.$medium-plus;
            color: utils.$color-accent;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .index-footer-year {
        h2 {
            font-size: utils.$medium-plus;
        }
    }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Contact Desktop */

#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

#contact-img-wrapper {
    width: 66%;
    height: 100vh;
    
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.contact-text-wrapper {
    width: 33%;
    height: 85vh;
    padding-top: 10vh;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

#contact-text-top {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2 {
        font-size: utils.$medium-plus;
    }
}

#contact-info-wrapper {
    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 2rem;

        li {
            font-size: utils.$extra;
            text-align: left;

            a {
                text-decoration: none;
                color: utils.$color-accent;
                transition: all 0.3s ease-in-out;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Bases Desktop */

#bases-content-wrapper {
    width: 100%;
    height: 100vh;
    gap: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

#bases-img {
    position: sticky; // Makes the image stay fixed when scrolling
    top: 0; // Sticks to the top when scrolling
    width: 55%;
    height: 100vh;
    margin-top: -10vh;
    background-image: url("../images/bases-img.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

#bases-content {
    height: 100vh; // Ensures content area is 100vh
    overflow-y: auto;
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;

    h2 {
        font-size: utils.$medium-plus;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 1rem;

        li {
            font-size: utils.$medium;
            text-align: left;
            font-family: 'Anaheim', sans-serif;
        }
    }
}

#bases-header {
    padding-top: 10vh;
    min-height: 20vh;

        h1 {
            width: 100%;
            font-size: 2.5rem;
        }

        p {
            width: 100%;
            font-size: utils.$medium;
        }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* About Desktop */
#about {
    gap: 0vh;
    margin-bottom: 10vh;
}

#about-header{
    min-height: 100vh;
    width: 90%;
    gap: 2rem;
    margin: 10vh;
    
}

#about-header-first {
    height: 75vh;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
}

.about-header-small,
.about-header-big {
    height: 75vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.about-header-small {
    background-image: url("#{utils.$images-path}/about-small.svg");
}

.about-header-big {
    background-image: url("#{utils.$images-path}/about-big.svg");
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

#about-header-first:hover .about-header-big {
    opacity: 1;
}

#about-header-second {
    width: 50%;
    min-height: 20vh;

    h2 {
        text-align: center;
        font-size: utils.$big;
    }
}

#about-header-third {
    width: 100%;
    height: auto;

    img {
        height: 20vh;
        width: auto;
        object-fit: contain;
        
    }

}

#about-carousel {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: opacity(0.5);
        transition: all 1s ease-in-out;
    }
}

#about-carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease-in-out; /* should match the one in script */ 
    opacity: 1; 
}

.about-carousel-text {
    width: 50%;
    position: absolute;
    padding: 1rem 0rem;

    p {
        font-family: 'petrona', serif;
        width: 100%;
        height: auto;
        font-weight: 400;
        font-size: utils.$medium-plus;
        text-align: center;
        line-height: utils.$big;
    }
}

.about-divider-img {
    height: 100vh;
    width: 100%;
    background-image: url("#{utils.$images-path}/about-divider-single.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 10vh;

    p {
        font-family: 'Petrona', serif;
        width: 50%;
        padding: 1rem;
        margin: 4rem;
        background-color: rgba(3, 3, 3, 0.5);
        font-size: utils.$medium-plus;
    }
}

.about-bottom {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-bottom-first {

    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        width: 50%;
        text-align: center;
        line-height: utils.$big;
    }
}

.about-bottom-second {
    width: 100%;
    height: 150vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}


.about-bottom-bottom {
    width: 50%;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Blog Desktop */
#blog-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
    gap: 2rem;
}

.blog-posts {
    width: 96%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-bottom: 5vh;

    a {
        text-decoration: none;
        
    }
}

.post {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    

    a {
        color: utils.$color-main;
        text-decoration: none;
        
    }
}

.post-cover {
    width: 100%;
    height: 50vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(50%); /* Example filter */
        transition: all 0.3s ease-in-out;
    
    }
}

.post-title-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.post-title {

    h2 {
        color: utils.$color-gray;
        text-decoration: none;
        font-size: utils.$big;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
        margin: 0;
    }

}

.post-date {

    p {
        color: utils.$color-gray;
        font-size: utils.$medium;
        transition: all 0.3s ease-in-out;
        margin: 0;
    }

}

.post-subtitle {
    
        p {
            color: utils.$color-gray;
            font-size: utils.$medium;
            text-align: left;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
}

/* Hover effect */
a.post:hover .post-cover img {
    filter: grayscale(0%); /* Remove filter on hover */
}

a.post:hover .post-title h2,
a.post:hover .post-date p,
a.post:hover .post-subtitle p {
    color: utils.$color-accent; /* Change text color on hover */
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;

    
}

.step-links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    a {
        color: utils.$color-accent;
        text-decoration: none;
        text-transform: capitalize;
        font-size: utils.$medium-plus;
        transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration: underline;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Blog detail Desktop */
#blog-detail {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.blog-detail-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5vh;
}

.blog-detail-cover {
    width: 100%;
    height: 66vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.blog-detail-title-row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.blog-detail-title {
    h1 {
        font-family: 'Petrona', serif;
        font-size: utils.$big;
        text-transform: capitalize;
        font-weight: 500;
        margin: 0;
    }
}

.blog-detail-date {
    p {
        font-size: utils.$medium;
        color: utils.$color-accent;
        margin: 0;
    }
}

.blog-detail-subtitle {
    width: 90%;
    p {
        font-size: utils.$medium-plus;
        color: utils.$color-accent;
        font-weight: 700;
        margin: 0;
    }
}

.blog-detail-text-one,
.blog-detail-text-two {
    width: 90%;
    p {
        font-size: utils.$medium-plus;
        color: utils.$color-accent;
        margin: 0;
    }
}

.blog-detail-separator-imgs {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin: 5vh 0;
}

.separator-img-1,
.separator-img-2 {
    width: 50%;
    height: 75vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.blog-detail-back {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5vh 0;

    a {
        color: utils.$color-accent;
        text-decoration: none;
        font-size: utils.$medium-plus;
        transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration: underline;
        }
    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Mobile Styles */
@media screen and (max-width: 800px) {
/* Index Mobile */

/* Index Navbar */
#index-navbar {
    width: 100%;
    min-height: 10vh;
}

.index-navbar-left,
.navbar-left {
    width: 0%;
    height: 100%;
    display: none;
    
}

.index-navbar-right,
.navbar-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding-right: 0rem;
}

.menu-button {
    color: utils.$color-accent;
    text-align: center;
    font-family: 'Petrona', serif;
    font-size: utils.$medium-plus;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {

        opacity: 0.5;
    }
}

.language-switcher {
    width: auto;
}

.navbar-languages {
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: nowrap;

    button {
        color: utils.$color-gray;
        font-family: 'Petrona', serif;
        text-transform: uppercase;
        font-size: utils.$medium-plus;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
        
}

/* The side navigation menu */
.sidenav {
    height: 100vh; /* Ensures it takes full screen height */
    width: 0; /* Initially hidden */
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: utils.$color-accent; 
    overflow-x: hidden;
    padding-top: 2rem; 
    transition: 0.5s; /* Smooth transition for width */
    color: utils.$color-main;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sidenav-wrapper {
    visibility: hidden; /* Hidden initially */
    opacity: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    transition: opacity 0.5s;
    margin-bottom: 5vh;
}

.navbar-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar-controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10vh;

    a {
        color: utils.$color-main;
        text-decoration: none;
        text-align: center;
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            color: utils.$color-brown;
        }
    }
}

#navbar-links {
    width: 100%;

}

.navbar-links-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;

    a {
        text-decoration: none;
    }
}

.navbar-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    
    h3, p {
        color: utils.$color-main;
        text-decoration: none;
        text-align: center;
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    p {
        font-size: utils.$tiny;
        margin: 0;
    }

    &:hover {

        h3,
        p {
            color: utils.$color-brown;
        }
    }
}

/* When sidenav is active */
.sidenav-active {
    width: 100vw; /* or adjust as per your requirement, like 25vw */
    height: 100vh; /* Full screen height */
}

.sidenav-active #sidenav-wrapper {
    visibility: visible; /* Make visible when active */
    opacity: 1; /* Fade in */
    display: flex;
}

#navbar-bottom-text {
    width: 100%;

    p {
        width: 80%;
        text-align: center;
        font-size: utils.$medium;
    }
}

/* Sidenav end */


#index-video {
    object-fit: cover;
}

.index-roca-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 80%;
    margin-left: 0%;
    }
}

#index-footer {
    width: 90%;
    height: 5vh;
    gap: 2rem;

    .index-footer-bases {
        display: none;
    }

    

    .index-footer-language {
        display: none;
    }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Contact Mobile */
#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

#contact-img-wrapper {
    width: 100%;
    height: 50vh;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.contact-text-wrapper {
    width: 90%;
    height: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

#contact-text-top {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2 {
        font-size: utils.$medium-plus;
    }
}

#contact-info-wrapper {
    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 2rem;

        li {
            font-size: utils.$extra;
            text-align: left;

            a {
                text-decoration: none;
                color: utils.$color-accent;
                transition: all 0.3s ease-in-out;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Bases Mobile */
#bases-content-wrapper {
    width: 100%;
    min-height: 100vh;
    height: auto;
    gap: 4rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
}

#bases-img {
    position: relative; // Makes the image stay fixed when scrolling
    top: 0; // Sticks to the top when scrolling
    width: 100%;
    height: 100vh;
    margin-top: 0vh;
    background-image: url("../images/bases-img.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

#bases-content {
    min-height: 100vh; // Ensures content area is 100vh
    overflow-y: auto;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;

    h2 {
        font-size: utils.$medium-plus;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 1rem;

        li {
            font-size: utils.$medium;
            text-align: left;
            font-family: 'Anaheim', sans-serif;
        }
    }
}

#bases-header {
    padding-top: 10vh;
    min-height: 20vh;

        h1 {
            width: 100%;
            font-size: 2.5rem;
        }

        p {
            width: 100%;
            font-size: utils.$medium;
        }

}

/* TODO: Implement this 

.bases-brands-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem; 
}

.bases-brand {
    
} */

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* About Mobile */
#about-header{
    min-height: 75vh;
}

#about-header-first {
    height: 40vh;
}

.about-header-small,
.about-header-big {
    height: 40vh;
    
}

.about-header-small {
    opacity: 0;
    background-image: url("#{utils.$images-path}/about-small.svg");
}

.about-header-big {
    background-image: url("#{utils.$images-path}/about-big.svg");
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

#about-header-first:hover .about-header-big {
    opacity: 1;
}

#about-header-second {
    width: 75%;
    min-height: 0;
    height: auto;

    h2 {
        text-align: center;
        font-size: utils.$medium-plus;
    }
}

#about-header-third {
    width: 100%;
    height: auto;

    img {
        height: 20vh;
        width: auto;
        object-fit: contain;
        
    }

}

#about-divider-img {
    height: 33vh;
    width: 90%;
    background-image: url("#{utils.$images-path}/about-divider.jpg");
}

#about-second {
    height: auto;
    width: 90%;
}

.about-content {
    p {
        font-family: 'petrona', serif;
        width: 100%;
        height: auto;
        font-size: utils.$medium;
        text-align: center;
    }

    span {
        font-size: utils.$medium-plus;
        font-weight: bold;
    }
}

.about-carousel-text {
    width: 90%;
    position: absolute;
    padding: 1rem 0rem;
}

.about-divider-img {
    height: 100vh;
    width: 100%;
    background-image: url("#{utils.$images-path}/about-divider-single.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10vh;

    p {
        font-family: 'Petrona', serif;
        width: 80%;
        padding: 1rem;
        margin: 0rem;
        margin-bottom: 2rem;
        background-color: rgba(3, 3, 3, 0.5);
        font-size: utils.$medium-plus;
    }
}

.about-bottom {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-bottom-first {

    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: 'Petrona', serif;
        font-size: utils.$medium-plus;
        width: 100%;
        text-align: center;
        line-height: utils.$big;
    }
}

.about-bottom-second {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5vh 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.about-bottom-bottom {
    width: 100%;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Blog Mobile */
#blog-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
    gap: 2rem;
}

.blog-posts {
    width: 96%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-bottom: 5vh;

    a {
        text-decoration: none;
        
    }
}

.post {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    

    a {
        color: utils.$color-main;
        text-decoration: none;
        
    }
}

.post-cover {
    width: 100%;
    height: 50vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(0%); /* Example filter */
        transition: all 0.3s ease-in-out;
    
    }
}

.post-title-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
}

.post-title {

    h2 {
        color: utils.$color-accent;
        text-decoration: none;
        font-size: utils.$medium-plus;
        font-weight: 500;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
    }

}

.post-date {

    p {
        color: utils.$color-accent;
        font-size: utils.$medium;
        transition: all 0.3s ease-in-out;
    }

}

.post-subtitle {
    
        p {
            color: utils.$color-accent;
            font-size: utils.$medium;
            text-align: left;
            transition: all 0.3s ease-in-out;
        }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Blog detail Mobile */
#blog-detail {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.blog-detail-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5vh;
}

.blog-detail-cover {
    width: 100%;
    height: 66vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.blog-detail-title-row {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.blog-detail-title {
    h1 {
        font-family: 'Petrona', serif;
        font-size: utils.$big;
        text-transform: capitalize;
        font-weight: 500;
        margin: 0;
    }
}

.blog-detail-date {
    p {
        font-size: utils.$medium;
        color: utils.$color-accent;
        margin: 0;
    }
}

.blog-detail-subtitle {
    width: 90%;
    p {
        font-size: utils.$medium-plus;
        color: utils.$color-accent;
        font-weight: 700;
        margin: 0;
    }
}

.blog-detail-text-one,
.blog-detail-text-two {
    width: 90%;
    p {
        font-size: utils.$medium-plus;
        color: utils.$color-accent;
        margin: 0;
    }
}

.blog-detail-separator-imgs {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 5vh 0;
}

.separator-img-1,
.separator-img-2 {
    width: 100%;
    height: 75vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.blog-detail-back {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;


    a {
        color: utils.$color-accent;
        text-decoration: none;
        font-size: utils.$medium-plus;
        transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration: underline;
        }
    }
}
    
} // Keep this line at the end of the file